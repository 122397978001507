*{
    font-family: "PoppinsRegular";
}
@font-face {
    font-family: 'handycheera';
    font-weight: bold;
    src: url('./components/fonts/Handycheera_Regular.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Flood';
    font-weight: bold;
    src: url('./components/fonts/Flood.otf');
    
  }
  @font-face {
    font-family: 'CenturyGothic';
    src: local('CenturyGothic'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'CenturyGothicBold';
    src: local('CenturyGothicBold'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: 'PoppinsBold';
  src: local('PoppinsBold'), url('./components/fonts/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url('./components/fonts/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
}

