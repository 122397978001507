.navbar-toggler{
  display: none !important;
  }
  @media only screen and (max-width: 991.7px) {
  .navbar>.container{
  justify-content: center !important;
  }
  }
  .navbar.active {
    background: #000000 !important;
}
  .nav-right{
   display: none;
  }
  .userWayContainer{
    margin-top: 0rem !important;
  }
