.banner,
.banner a {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center !important;
    color: #ffff;
    font-family: "PoppinsRegular";
    position: relative;
    text-decoration: none;
}

.banner a {
    text-decoration: underline;
}

.magic {
    font-family: "handycheera";
}

.Timer {

    align-items: center;
}

.Countdownheading {
    margin: 1rem 0 -0.8rem 0;
    font-size: 1.8rem;
}


.usemorganbutton {
    background: linear-gradient(#FC7D09, #FF7E07, #C23507);
    padding: 6px 22px;
    border-radius: 8px;
    border: none;
    color: #ffff;
    font-weight: 600;
}

@media screen and (min-width:2000px) {
    .oneliner {
        margin-top: 10rem !important;
    }

}

@media screen and (min-width:992px) {
    .three {
        position: absolute;
        margin-left: 19rem;
        top: 11rem;
    }

    .Natarajanimation {
        display: none;
    }

    .banner {
        padding-bottom: 14rem;
    }

    .phasetwo {
        margin: 1rem 0 3rem 0 !important;
    }

    .usemorganbutton {
        margin: 0rem 0 2rem 0;
    }

    .two1,
    .mobile {
        display: none;
    }

    .two {
        position: absolute;
        margin-left: -17rem;
    }

    .TM {
        font-size: 14px;
        position: absolute;

        margin-top: 25.3rem !important;
    }

    .magic {
        font-size: 4rem;
    }

    .usemorganbutton {
        font-size: 1.5rem;
    }

    .sunwest-logo {
        position: absolute;
        top: 10px;
        cursor: pointer;
        height: 55px;
    }

    .webinardetails {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.37rem;
        margin-bottom: 3rem;
        font-weight: 600;
        line-height: 1;
    }

    .webinardetails p a {
        text-decoration: underline;
    }

    .topic {
        width: 255px;
        padding: 10px 10px 0 0;
        border-right: 1px solid #ffff;
    }

    .date {
        padding: 10px 0 0 35px;

    }

    .time {
        padding: 0px 0 0 23px;
    }

    .oneliner {
        font-family: "Poppins";
        font-style: normal;
        font-size: 50px;
        text-align: left;
        background: linear-gradient(89deg, #FF541C 0%, #F39723 50%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        margin-top: 15rem;
        line-height: 134.826%;

    }

    .Headerline {
        color: #FFF;
        font-family: "Poppins";
        text-align: left;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: 134.826%;
        margin-top: -1rem;
    }

    .banner {
        background-image: url("https://resources.swmc.com/natarajai/Desktopgif.gif");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center !important;
        color: #ffff;
        font-family: "PoppinsRegular";
        position: relative;
        text-decoration: none;
        border-bottom: 18px solid #F2A024;
    }

    .onelinermobile {
        display: none;
    }

}

@media only screen and (max-width: 991.7px) {
    .usemorganbutton {
        margin: 0rem 0 2rem 0 !important;
    }

    .Headerline {
        display: none;
    }

    .Natarajanimation {
        width: 100%;
        height: 650px;
    }

    .mobileHeaderline {
        background: linear-gradient(89deg, #FF541C 0%, #F39723 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: "Poppins";
        font-size: 8vw;
        font-style: normal;
        font-weight: 600;
        line-height: 134.826%;
        position: relative;
        z-index: 4;
        margin-top: -11rem;
        /* 44.493px */
    }

    .mobileHeaderline2 {
        color: #FFF;
        text-align: center;
        font-family: "Poppins";
        font-size: 7vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .trade1 {
        font-weight: 400;
    }

    .three {
        width: 22px;
        position: absolute;
        margin-left: 22vw;
        /* top: 24%; */
        margin-top: 10vw;
    }

    .two,
    .desktop {
        display: none;
    }

    .two1 {
        position: absolute;
        margin-top: 21vw;
        margin-left: -27vw;
        width: 20px;
    }

    .trade {
        font-weight: 400;
    }

    .magic {
        font-size: 15vw;
        margin-top: -9.5vw;
    }

    .usemorganbutton {
        font-size: 4vw;
    }

    .webinardetails {
        color: #ffff;
        text-align: center;
        margin-bottom: 1rem;
        padding: 0 15px;
        font-weight: 600;
        font-size: 4.5vw;
    }

    .Countdownheading {
        margin: 0rem 0 -0.5rem 0;
        font-size: 5vw;
    }

    .onelinermobile {
        font-size: 6vw;
        font-weight: 600;
        line-height: 1.55;
    }

    .banner {
        background: #000000;
        height: 650px;

    }

    .oneliner {
        display: none;
    }

}

@media screen and (min-width: 661px) and (max-width:991px) {

    .webinardetails {
        font-size: 3.5vw;
    }

}
@media screen and (min-width: 481px) and (max-width:991px) {

    .mobileHeaderline{
        font-size: 2.5rem;
    }
    .mobileHeaderline2{
        font-size: 2rem;
    }
    .banner{
        height: 685px;
    }

}