.form-container {
  margin-top: 3rem;
  padding: 12px 12px 0 12px;
  font-family: 'Poppins' !important;
}

.user {

  fill: #F0562A !important;


}

.was-validated .form-control:invalid {
  border-color: #ced4da !important;
}

.was-validated .form-control:valid {
  border-color: #ced4da !important;
}

.formWrapper {
  position: relative;

}

.shadow {
  display: flex;
  justify-content: center;
  box-shadow: none !important;
}

.formHeader p,
.formHeader p a {
  color: #083F88;
  line-height: 1.65;
  font-weight: 500;
  text-decoration: none;

}

.formHeader h3,
.formHeader h3 a {
  color: #E53E08;
  font-weight: 600;
  text-decoration: none;

}

.formHeader a:hover {
  text-decoration: underline;
}


.signupForm,
.form-select {
  color: #605852CC !important;
}

#signupForm h6 {
  margin: 0;
  padding: 8px 0;
}

#signupForm .form-group {
  padding: 0 0 8px 0;
}

#signupForm .form-control {
  font-size: 12px;
  border-radius: 0 5px 5px 0;
}

#signupForm .form-label {
  font-size: 15px;
}

#signupForm .other .form-control {
  font-size: 15px !important;
}

#signupForm .form-control:focus {
  border-color: #2ba8e0;
}

#signupForm .text-blue {
  color: #00208b;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}

#signupForm .consent-font {
  text-align: justify;
  margin-bottom: 0;
  padding-bottom: 8px;
  font-size: 10px;
  color: #605852;
}
.consent-font a {
  color: #605852;
}

#user-icon {
  background-color: #fff;
}

#signupForm .form-check {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}

#signupForm .form-check-input[type="radio"] {
  border-radius: 0.25em;
}

#signupForm .form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}

#signupForm .form-check-label {
  padding: 0 1.5rem 0 0;
  font-weight: 500;
}

#signupForm .form-link {
  text-decoration: none;
}

#signupForm .form-link:hover {
  text-decoration: underline;
}

#signupForm .btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto 12px;
  padding: 0.3rem 1.5rem;
  gap: 1vw;
  border-radius: 0.5em;
  text-align: center;

}

#signupForm .blg-row-2 {
  margin-top: -8px;
  justify-content: center;
}

#signupForm img.blg-img {
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0 12px;
}

#signupForm h4 {
  color: #00208b;
}

#signupForm .btn-start,
#signupForm .btn-blg {
  margin: 8px 0;
  font-weight: 700;
}

#signupForm .btn-blg:hover {
  color: #ffffff;
}

#signupForm .btn-start {
  background-color: #083f88;
  border-color: #083f88;
  color: #ffffff;
}

#signupForm .btn-start:hover {
  background-color: #2ba8e0;
  border-color: #2ba8e0;
  color: #ffffff;
}

#signupForm .divflex {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

#signupForm .backBtnDiv {
  align-self: flex-start;
}

#signupForm .continueBtnDiv {
  position: absolute;
}

#signupForm #showConsentText {
  display: inline-block;
}

#signupForm #hideConsentText {
  display: inline-block;
}

#signupForm .consentSmall {
  cursor: pointer;
  color: #0d6efd;
}

#signupForm .consentSmall:hover {
  color: #00aae4;
}

#signupForm .consentLink {
  cursor: pointer;
  color: #0d6efd;
  padding: 8px 0px;
  font-size: 0.7rem;
}

#signupForm .consentLink:hover {
  text-decoration: underline;
  color: #00aae4;
}

#signupForm .whiteText {
  color: #ffffff;
}



@media screen and (max-width: 350px) {
  #signupForm .btnAdjustDiv {
    left: 35% !important;
  }
}

@media screen and (max-width: 416px) {
  #signupForm .consentLink {
    font-size: 0.625rem;
  }

  #signupForm .btnAdjustDiv {
    left: 30%;
  }
}

#signupForm .btn-submit {
  background-color: #00134C;
  border: none;
}

@media only screen and (min-width: 992px) and (max-width: 1335px) {
  #signupForm .btn-success {
    margin-right: -6vw !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {

  .Referral {
    margin-top: -1rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {

  .dots2,
  .blueshade,
  .blueshade1,
  .flower3,
  .dots3,
  .dots1 {
    display: none;
  }
  .form-container {
    margin-top: 1rem;
  }

  .ContactUsFormBox {
    max-width: 100%;
    border-radius: 23px !important;
    padding: 0 1rem 1rem 1rem !important;

  }

  .formWrapper {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 320px) and (max-width: 643px) {
    .formHeader p {
      font-size: 4vw !important;
      margin-top: -10px;
    }

    .formHeader h3 {
      font-size: 5vw !important;
    }

  }


  #signupForm .btn-submit {
    background-color: #00134C;
    border-radius: 20px;
  }
}

.footeranchers{
  text-decoration: underline;
  color: #605852;
}

@media screen and (min-width: 992px) {

  /* .blueshade{
      transform: rotate(180deg);
      position: absolute;
      right: 0rem;
      top: -17rem;
      z-index: -2;
      height: 100%;
     
  } */
  .pad-form {
    background-image: url("https://resources.swmc.com/natarajai/formbgdesk.webp");
    background-repeat: no-repeat;
    background-size: 100% 130%;
  }

  .blueshade1 {

    position: absolute;
    left: 0rem;
    top: 12rem;
    z-index: -2;
    height: 100%;

  }

  .formHeader p {
    font-size: 1.2rem;
    margin-top: -12px;
  }

  .formHeader h3 {
    font-size: 1rem;
  }

  .grad4 {
    width: 177px;
    height: 350px;
    background-image: radial-gradient(180px circle at 0% 50%, #F0562A42, #fff);
    position: absolute;
    margin-top: -8rem;
    z-index: -2;
  }

  .grad5 {
    width: 150px;
    height: 350px;
    background-image: radial-gradient(180px circle at 0% 50%, #00AAE426, #fff);
    position: absolute;
    margin-top: -10rem;
    z-index: -2;
    right: 0;
    transform: rotate(180deg);
  }

  .dots2,
  .dots1,
  .dots3 {
    position: absolute;
    width: 40px;
  }

  .dots1 {
    margin-left: 15vw;
  }

  .dots2 {
    right: 0%;
    top: 9rem;
  }

  .dots3 {
    right: 10%;
  }

  #signupForm .btn-submit {

    border-radius: 50px;
  }

}

.ContactUsFormBox {
  background: #E4F1FF;
  border-radius: 33px 33px 0 0;
  box-shadow: 0px 0px 9.471096992492676px 4.735548496246338px rgba(0, 0, 0, 0.25);
  padding: 0 2rem 1rem 2rem;
  /* margin-bottom: 3rem; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px){
  .ContactUsFormBox{
  width: 800px;
  height: auto;
  }

} */

/* @media only screen and (min-width: 1200px) and (max-width: 1399px){
  .ContactUsFormBox{
    width: 1000px;
    height: auto;
    }
} */

/* @media only screen and (min-width: 1400px) {
  .ContactUsFormBox{
   width: 1200px;
  }
} */

@media screen and (min-width: 644px) and (max-width: 991px) {
  .formHeader p {
    font-size: 2.8vw !important;
    margin-top: -10px;
  }

}